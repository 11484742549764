import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { dateFormatter, buttonsStyle, longTextFormatter, booleanFormatter } from '../../../components/tables/table'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getCrops, deleteCrop } from '../../../../api/crop'
import { Button, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { alertError, alertSuccess } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'
import { getCropTypes } from '../../../../api/cropType'

function getData(crops, cropTypes) {
  let data = []
  for (let i = 0; i < crops.length; ++i) {
      const elem = {}
      elem.name = crops[i].name
      elem.description = crops[i].description || '---'
      elem.cropType = cropTypes.find(item => item._id === crops[i].cropType)?.name || '---'
      elem.createdAt = crops[i].createdAt
      elem.active = crops[i].active
      elem.id = crops[i]._id
      data = data.concat(elem)
  }
  return data
}

export default function CropsPage() {
  const [data, setData] = useState([])
  const [cropId, setCropId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Edit">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/edit-crop/' + cell)}>
          <EditIcon/>
        </Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setCropId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon/>
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'name', text: 'Name', sort: true },
    { dataField: 'description', text: 'Description', formatter: longTextFormatter, sort: true },
    { dataField: 'cropType', text: 'Type', sort: true },
    { dataField: 'active', text: 'Active', align: 'center', headerAlign: 'center', formatter: booleanFormatter, sort: true },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getCropTypes().then(resp => {
      if (resp.status === 200) {
        getCrops().then((res) => {
          if (res.status === 200) {
            setData(getData(res.data, resp.data))
            setRefresh(false)
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get crops.' })
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get types of crops.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Crops list'>
          <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push('/edit-crop')}
              >
                Add new
              </button>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'Are you sure you want to remove this crop?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteCrop(cropId).then(res => {
                if (res.status === 204 || res.status === 200 || res.status === 200) {
                  setRefresh(true)
                  alertSuccess({  title: 'Deleted!', customMessage: 'Crop removed successfully.' })
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not remove crop.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
