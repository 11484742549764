import { authClient, API } from '../index'

// Count all posts
export const countPosts = () => {
  return authClient().get(`${API}/post/count`)
}

// Sync all posts
export const syncPosts = () => {
  return authClient().get(`${API}/post/sync`)
}

// Get all posts
export const getPosts = () => {
  return authClient().get(`${API}/post?getDisabled=true`)
}

// Get post by id
export const getPostById = (id) => {
  return authClient().get(`${API}/post/${id}`)
}

// Update post
export const updatePost = async (id, active) => {
    return authClient().put(`${API}/post/${id}`, { active })
}