import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls'
import {
	Button,
  TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { getCategories, getCategoryById, updateCategory } from '../../../../../api/category'
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from '../../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../../utils/logger'
import Editor from '../../../../components/editor/Editor'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

export default function ViewCategoriesPage() {
  const [category, setCategory] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const categoryId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!categoryId) {
      disableLoadingData()
      history.push('/product-categories')
      return
    }
    getCategories().then(resp => {
      if (resp.status === 200) {
        getCategoryById(categoryId).then(res => {
          if (res.status === 200) {
            setCategory({...res.data, parent: resp.data.find(item => item.wordpress_id === res.data.parent)?.name})
            disableLoadingData()
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get category.' })
          history.push('/product-categories')
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get categories.' })
      history.push('/product-categories')
    })
  }, [categoryId, disableLoadingData, history])

  if (isLoadingData || !category)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='View category'></CardHeader>
        <CardBody>
          <TextField
            id={`name`}
            label="Name"
            value={category.name}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`slug`}
            label="Slug"
            value={category.slug}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />   
          <TextField
            id={`parent`}
            label="Parent category"
            value={category.parent || '---'}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />     
          <br/>
          <Editor
            body={category.description}
            className='max-height'
            disabled
            label="Description"
          />
          <TextField
            id={`count`}
            label="Quantity"
            value={category.count}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          {/* TODO: DISPLAY IMAGE?? */}
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={category.active} disabled name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/product-categories')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <MuiThemeProvider theme={theme}>
        <Button
          onClick={() => setOpenConfirmDialog(true)}
          variant="outlined"
          color={category.active ? "secondary" : "primary"}>
          {`${category.active ? 'Disable' : 'Enable'} category`}
        </Button>
      </MuiThemeProvider>
      <ConfirmDialog
        title={`Are you sure you want to ${category.active ? 'disable' : 'enable'} this category?`}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          updateCategory(categoryId, !category.active).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: `${category.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Category ${category.active ? 'disabled' : 'enabled'} successfully` })
              history.push('/product-categories')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: `Could not ${category.active ? 'disable' : 'enable'} category.` })
          })
        }}
      />
    </>
  );
}