import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

const PreviewDialog = (props) => {
  const { title, open, src, setOpen } = props

  return (
    <Dialog
      fullWidth={true}
      size="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <embed
            src={src}
            height="500px"
            width="500px" />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false)
          }}
          color="default"
        >
          Ok!
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default PreviewDialog
