import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls'
import Table, { buttonsStyle, longTextFormatter, booleanFormatter } from '../../../../components/tables/table'
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog'
import { getTags, updateTag, syncTags } from '../../../../../api/tag'
import { Button, Tooltip } from '@material-ui/core'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import VisibilityIcon from '@material-ui/icons/Visibility'
import { alertError, alertSuccess, alertLoading } from '../../../../../utils/logger'
import { useHistory } from 'react-router-dom'

function getData(tags) {
  let data = []
  for (let i = 0; i < tags.length; ++i) {
    if (!tags[i].isPostTag) {
      const elem = {}
      elem.name = tags[i].name
      elem.description = tags[i].description || '---'
      elem.quantity = tags[i].count
      elem.active = tags[i].active
      elem.id = tags[i]._id
      data = data.concat(elem)
    }
  }
  return data
}

export default function TagsPage() {
  const [data, setData] = useState([])
  const [tag, setTag] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    const elem = data.find(item => item.id === cell)
    return (<>
      <Tooltip title="View">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/product-view-tag/' + cell)}>
          <VisibilityIcon/>
        </Button>
      </Tooltip>
      <Tooltip title={elem?.active ? "Disable" : "Enable"}>
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setTag(elem)
            setOpenConfirmDialog(true)
          }}>
          {elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon/>}
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'name', text: 'Name', sort: true },
    { dataField: 'description', text: 'Description', formatter: longTextFormatter, sort: true },
    { dataField: 'quantity', text: 'Quantity', sort: true },
    { dataField: 'active', text: 'Active', align: 'center', headerAlign: 'center', formatter: booleanFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getTags().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get tags.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Tags list'>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                alertLoading({ title: 'Getting all tags...'})
                syncTags().then(res => {
                  if (res.status === 200) {
                    alertSuccess({ title: 'Done!', customMessage: 'All tags have been imported successfully.' })
                    setRefresh(true)
                  }
                }).catch(error => {
                  alertError({ error: error, customMessage: 'Could not sync tags.' })
                })
              }}
            >
              Sync tags
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={`Are you sure you want to ${tag?.active ? 'disable' : 'enable'} this tag?`}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              updateTag(tag.id, !tag.active).then(res => {
                if (res.status === 204 || res.status === 200) {
                  alertSuccess({ title: `${tag.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Tag ${tag.active ? 'disabled' : 'enabled'} successfully` })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: `Could not ${tag.active ? 'disable' : 'enable'} tag.` })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
