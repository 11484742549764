import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../../../_metronic/_partials/controls'
import {
	Button,
  TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { getTermById, updateTerm } from '../../../../../../api/term'
import { getAttributes } from '../../../../../../api/attribute'
import ConfirmDialog from "../../../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from '../../../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../../../utils/logger'
import Editor from '../../../../../components/editor/Editor'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

export default function ViewAttributeTermsPage() {
  const [attributeTerm, setAttributeTerm] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const attributeTermId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!attributeTermId) {
      disableLoadingData()
      history.push('/product-terms-attributes')
      return
    }
    getAttributes().then(resp => {
      if (resp.status === 200) {
        getTermById(attributeTermId).then(res => {
          if (res.status === 200) {
            setAttributeTerm({...res.data, parent: resp.data.find(item => item.wordpress_id === res.data.parent)?.name})
            disableLoadingData()
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get attribute term.' })
          history.push('/product-terms-attributes')
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get attribute.' })
      history.push('/product-terms-attributes')
    })
  }, [attributeTermId, disableLoadingData, history])

  if (isLoadingData || !attributeTerm)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='View attribute term'></CardHeader>
        <CardBody>
        <TextField
            id={`name`}
            label="Name"
            value={attributeTerm.name}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`slug`}
            label="Slug"
            value={attributeTerm.slug}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />   
          <TextField
            id={`parent`}
            label="Attribute"
            value={attributeTerm.parent || '---'}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />     
          <br/>
          <Editor
            body={attributeTerm.description}
            className='max-height'
            disabled
            label="Description"
          />
          <TextField
            id={`count`}
            label="Quantity"
            value={attributeTerm.count}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`menu_order`}
            label="Menu order"
            value={attributeTerm.menu_order}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={attributeTerm.active} disabled name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/product-terms-attributes')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <MuiThemeProvider theme={theme}>
        <Button
          onClick={() => setOpenConfirmDialog(true)}
          variant="outlined"
          color={attributeTerm.active ? "secondary" : "primary"}>
          {`${attributeTerm.active ? 'Disable' : 'Enable'} attribute`}
        </Button>
      </MuiThemeProvider>
      <ConfirmDialog
        title={`Are you sure you want to ${attributeTerm.active ? 'disable' : 'enable'} this attribute term?`}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          updateTerm(attributeTermId, !attributeTerm.active).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: `${attributeTerm.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Attribute term ${attributeTerm.active ? 'disabled' : 'enabled'} successfully` })
              history.push('/product-terms-attributes')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: `Could not ${attributeTerm.active ? 'disable' : 'enable'} attribute term.` })
          })
        }}
      />
    </>
  );
}