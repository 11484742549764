import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls'
import {
	Button,
  TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { getAttributeById, updateAttribute } from '../../../../../api/attribute'
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from '../../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../../utils/logger'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

export default function ViewAttributesPage() {
  const [attribute, setAttribute] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const attributeId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!attributeId) {
      disableLoadingData()
      history.push('/product-attributes')
      return
    }
    getAttributeById(attributeId).then(res => {
      if (res.status === 200) {
        setAttribute(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get attribute.' })
      history.push('/product-attributes')
    })
  }, [attributeId, disableLoadingData, history])

  if (isLoadingData || !attribute)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='View attribute'></CardHeader>
        <CardBody>
        <TextField
            id={`name`}
            label="Name"
            value={attribute.name}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`slug`}
            label="Slug"
            value={attribute.slug}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />   
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={attribute.active} disabled name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/product-attributes')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <MuiThemeProvider theme={theme}>
        <Button
          onClick={() => setOpenConfirmDialog(true)}
          variant="outlined"
          color={attribute.active ? "secondary" : "primary"}>
          {`${attribute.active ? 'Disable' : 'Enable'} attribute`}
        </Button>
      </MuiThemeProvider>
      <ConfirmDialog
        title={`Are you sure you want to ${attribute.active ? 'disable' : 'enable'} this attribute?`}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          updateAttribute(attributeId, !attribute.active).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: `${attribute.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Attribute ${attribute.active ? 'disabled' : 'enabled'} successfully` })
              history.push('/product-attributes')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: `Could not ${attribute.active ? 'disable' : 'enable'} attribute.` })
          })
        }}
      />
    </>
  );
}