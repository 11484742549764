import { authClient, API } from '../index'

// Count all terms
export const countTerms = () => {
  return authClient().get(`${API}/term/count`)
}

// Sync all terms
export const syncTerms = () => {
  return authClient().get(`${API}/term/sync`)
}

// Get all terms
export const getTerms = () => {
  return authClient().get(`${API}/term?getEmpty=true`)
}

// Get term by id
export const getTermById = (id) => {
  return authClient().get(`${API}/term/${id}`)
}

// Update term
export const updateTerm = async (id, active) => {
    return authClient().put(`${API}/term/${id}`, { active })
}