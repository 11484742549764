import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
  TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { getProductById, updateProduct } from '../../../../api/product'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import Editor from '../../../components/editor/Editor'
import { dateFormatter } from '../../../components/tables/table'
import PreviewDialog from "../../../components/dialogs/PreviewDialog"

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

export default function ViewProductsPage() {
  const [product, setProduct] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false)
  const productId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!productId) {
      disableLoadingData()
      history.push('/products')
      return
    }
    getProductById(productId).then(res => {
      if (res.status === 200) {
        setProduct(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get product.' })
      history.push('/products')
    })
  }, [productId, disableLoadingData, history])

  if (isLoadingData || !product)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='View product'></CardHeader>
        <CardBody>
          {/* TODO: SHOW PRODUCT INFO */}
          <TextField
            id={`name`}
            label="Name"
            value={product.name}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`slug`}
            label="Slug"
            value={product.slug}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />     
          <TextField
            id={`sku`}
            label="SKU"
            value={product.sku}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />       
          <br/>
          <Editor
            body={product.description}
            className='max-height'
            disabled
            label="Description"
          />
          <br/>
          <Editor
            body={product.short_description}
            className='max-height'
            disabled
            label="Short description"
          />
          <TextField
            id={`categories`}
            label="Categories"
            value={product.categories?.map(item => item.name).join(', ') || '---'}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`tags`}
            label="Tags"
            value={product.tags?.map(item => item.name).join(', ') || '---'}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`attributes`}
            label="Attributes"
            value={product.attributes?.map(item => item.name).join(', ') || '---'}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`average_rating`}
            label="Average rating"
            value={product.average_rating}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />  
          <TextField
            id={`rating_count`}
            label="Rating count"
            value={product.rating_count}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />  
          <TextField
            id={`button_text`}
            label="Button text"
            value={product.button_text}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />  
          <TextField
            id={`catalog_visibility`}
            label="Catalog visibility"
            value={product.catalog_visibility}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />  
          <TextField
            id={`backorders`}
            label="Backorders"
            value={product.backorders}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />  
          <TextField
            id={`dimensions`}
            label="Dimensions"
            value={'Width: ' + (product.dimensions.width || '--') + ', Length: ' + (product.dimensions.length || '--') + ', Height: ' + (product.dimensions.height || '--')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`weight`}
            label="Weight"
            value={product.weight}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`external_url`}
            label="External URL"
            value={product.external_url}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`permalink`}
            label="Permalink"
            value={product.permalink}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`price`}
            label="Price"
            value={product.price}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`regular_price`}
            label="Regular price"
            value={product.regular_price}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`sale_price`}
            label="Sale price"
            value={dateFormatter(product.sale_price)}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`date_created`}
            label="Date created"
            value={dateFormatter(product.date_created)}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`date_modified`}
            label="Date modified"
            value={dateFormatter(product.date_modified)}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`date_on_sale_from`}
            label="Date on sale from"
            value={dateFormatter(product.date_on_sale_from)}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`date_on_sale_to`}
            label="Date on sale to"
            value={dateFormatter(product.date_on_sale_to)}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`shipping_class`}
            label="Shipping class"
            value={product.shipping_class}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`status`}
            label="Status"
            value={product.status}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`tax_class`}
            label="Tax class"
            value={product.tax_class}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`tax_status`}
            label="Tax status"
            value={product.tax_status}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`total_sales`}
            label="Total sales"
            value={product.total_sales}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`type`}
            label="Type"
            value={product.type}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`stock_quantity`}
            label="Stock quantity"
            value={product.stock_quantity}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={product.manage_stock} disabled name="checkManageStock" />}
            label="Manage stock"
          />
          <FormControlLabel
            control={<Checkbox checked={product.in_stock} disabled name="checkInStock" />}
            label="In stock"
          />
          <FormControlLabel
            control={<Checkbox checked={product.backordered} disabled name="checkBackordered" />}
            label="Backordered"
          />
          <FormControlLabel
            control={<Checkbox checked={product.backorders_allowed} disabled name="checkBackordersAllowed" />}
            label="Backorders allowed"
          />
          <FormControlLabel
            control={<Checkbox checked={product.downloadable} disabled name="checkDownloadable" />}
            label="Downloadable"
          />
          <FormControlLabel
            control={<Checkbox checked={product.on_sale} disabled name="checkOnSale" />}
            label="On sale"
          />
          <FormControlLabel
            control={<Checkbox checked={product.featured} disabled name="checkFeatured" />}
            label="Featured"
          />
          <FormControlLabel
            control={<Checkbox checked={product.purchasable} disabled name="checkPurchasable" />}
            label="Purchasable"
          />
          <FormControlLabel
            control={<Checkbox checked={product.virtual} disabled name="checkVirtual" />}
            label="Virtual"
          />
          <FormControlLabel
            control={<Checkbox checked={product.reviews_allowed} disabled name="checkReviewsAllowed" />}
            label="Reviews allowed"
          />
          <FormControlLabel
            control={<Checkbox checked={product.shipping_required} disabled name="checkShippingRequired" />}
            label="Shipping required"
          />
          <FormControlLabel
            control={<Checkbox checked={product.shipping_taxable} disabled name="checkShippingTaxable" />}
            label="Shipping taxable"
          />
          <FormControlLabel
            control={<Checkbox checked={product.sold_individually} disabled name="checkSoldIndividually" />}
            label="Sold individually"
          />
          <TextField
            id={`menu_order`}
            label="Menu order"
            value={product.menu_order}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`related_products`}
            label="Related products"
            value={product.related_products?.join(', ') || '---'}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <br />
          <br />
          {/* TODO: SHOW PRODUCT DOWNLOADS */}
          {product.images?.map(image => (<>
            <img key={image.id} src={image.src} alt={image.alt} style={{ maxWidth: '200px', cursor: 'zoom-in' }} onClick={() => setOpenPreviewDialog(true)}/>
            <PreviewDialog
              open={openPreviewDialog}
              setOpen={setOpenPreviewDialog}
              src={image.src}
            />
          </>))}
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={product.active} disabled name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/products')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <MuiThemeProvider theme={theme}>
        <Button
          onClick={() => setOpenConfirmDialog(true)}
          variant="outlined"
          color={product.active ? "secondary" : "primary"}>
          {`${product.active ? 'Disable' : 'Enable'} product`}
        </Button>
      </MuiThemeProvider>
      <ConfirmDialog
        title={`Are you sure you want to ${product.active ? 'disable' : 'enable'} this product?`}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          updateProduct(productId, !product.active).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: `${product.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Product ${product.active ? 'disabled' : 'enabled'} successfully` })
              history.push('/products')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: `Could not ${product.active ? 'disable' : 'enable'} product.` })
          })
        }}
      />
    </>
  );
}
