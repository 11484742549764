import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteSector, getSectorById, postSector, updateSector } from '../../../../api/sector'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

function getEmptySector() {
  return {
    fullName: {},
    active: true
  }
}

export default function EditSectorsPage() {
  const [sector, setSector] = useState(getEmptySector())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const sectorId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!sectorId) {
      disableLoadingData()
      return
    }
    getSectorById(sectorId).then(res => {
      if (res.status === 200) {
        setSector(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get sector.' })
      history.push('/sectors')
    })
  }, [sectorId, disableLoadingData, history])

  function saveSector() {
    if (!sectorId) {
      postSector(sector).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Sector successfully created.' })
          history.push('/sectors')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save sector.' })
      })
    } else {
      updateSector(sectorId, sector).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/sectors')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang || lang === 0) {
      if (event.target.value === ' ') return
      if (!sector[element]) sector[element] = {}
      let newText = sector[element]
      newText[lang] = event.target.value
      setSector({ ...sector, [element]: newText })
    } else setSector({ ...sector, [element]: event.target.value })
  }

  const renderMultilanguageTabContent = lang => {
    return (
      <>
        <TextField
          id={`fullName`}
					label="Name"
          value={(sector.fullName && sector.fullName[lang]) || ''}
          onChange={handleChange('fullName', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        />
      </>
    )
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit sector'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={sector.active} onChange={() => setSector({ ...sector, active: !sector.active })} name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/sectors')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveSector()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save sector
      </Button>
      {sectorId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete sector
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this sector?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteSector(sectorId).then(res => {
              if (res.status === 204 || res.status === 200) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Sector deleted successfully' })
                history.push('/sectors')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete sector.' })
            })
          }}
        />
      </>}
    </>
  );
}
