/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
//import LanguageIcon from '@material-ui/icons/Language'
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import WidgetsIcon from '@material-ui/icons/Widgets';
import BallotIcon from '@material-ui/icons/Ballot';
import SettingsIcon from '@material-ui/icons/Settings';
import CategoryIcon from '@material-ui/icons/Category';
import WorkIcon from '@material-ui/icons/Work';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import StorageIcon from '@material-ui/icons/Storage';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
import AnnouncementIcon from '@material-ui/icons/Announcement';

export function AsideMenuList({ layoutProps }) {
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: '';
	};

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/dashboard'>
						<span className='svg-icon menu-icon'>
							<SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
						</span>
						<span className='menu-text'>Dashboard</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>PRODUCTS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/products',
						false
					)} ${getMenuItemActive('/view-product', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/products'>
						<span className='menu-icon'>
							<SettingsIcon />
						</span>
						<span className='menu-text'>Products</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/product-categories'
					)} ${getMenuItemActive('/product-view-category')}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/product-categories'>
						<span className='svg-icon menu-icon'>
							<WidgetsIcon />
						</span>
						<span className='menu-text'>Categories</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/product-tags'
					)} ${getMenuItemActive('/product-view-tag')}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/product-tags'>
						<span className='svg-icon menu-icon'>
							<LocalOfferIcon />
						</span>
						<span className='menu-text'>Tags</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/product-attributes'
					)} ${getMenuItemActive('/product-view-attribute')}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/product-attributes'>
						<span className='svg-icon menu-icon'>
							<StorageIcon />
						</span>
						<span className='menu-text'>Attributes</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/product-terms-attributes'
					)} ${getMenuItemActive('/product-view-term-attribute')}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/product-terms-attributes'>
						<span className='svg-icon menu-icon'>
							<FormatListBulletedIcon />
						</span>
						<span className='menu-text'>Terms of attributes</span>
					</NavLink>
				</li>

				<li className='menu-section'>
					<h4 className='menu-text'>CROPS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/crops',
						false
					)} ${getMenuItemActive('/edit-crop', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/crops'>
						<span className='menu-icon'>
							<BallotIcon />
						</span>
						<span className='menu-text'>Crops</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/types-crops'
					)} ${getMenuItemActive('/edit-type-crop')}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/types-crops'>
						<span className='svg-icon menu-icon'>
							<CategoryIcon />
						</span>
						<span className='menu-text'>Types of crops</span>
					</NavLink>
				</li>

				<li className='menu-section'>
					<h4 className='menu-text'>BLOG</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/posts',
						false
					)} ${getMenuItemActive('/edit-post', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/posts'>
						<span className='menu-icon'>
							<AnnouncementIcon />
						</span>
						<span className='menu-text'>Blog posts</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/post-categories'
					)} ${getMenuItemActive('/post-view-category')}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/post-categories'>
						<span className='svg-icon menu-icon'>
							<WidgetsIcon />
						</span>
						<span className='menu-text'>Categories</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/post-tags'
					)} ${getMenuItemActive('/post-view-tag')}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/post-tags'>
						<span className='svg-icon menu-icon'>
							<LocalOfferIcon />
						</span>
						<span className='menu-text'>Tags</span>
					</NavLink>
				</li>

				<li className='menu-section'>
					<h4 className='menu-text'>USERS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/users',
						false
					)} ${getMenuItemActive('/view-user', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/users'>
						<span className='menu-icon'>
							<PeopleIcon />
						</span>
						<span className='menu-text'>App Users</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/admins',
						false
					)} ${getMenuItemActive('/edit-admin', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/admins'>
						<span className='menu-icon'>
							<AssignmentIndIcon />
						</span>
						<span className='menu-text'>Administrators</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>OTHERS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				{/*<li
					className={`menu-item ${getMenuItemActive('/languages', false)} ${getMenuItemActive('/edit-language', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/languages">
						<span className="menu-icon">
							<LanguageIcon/>
						</span>
						<span className="menu-text">Languages</span>
					</NavLink>
				</li>*/}
				<li
					className={`menu-item ${getMenuItemActive(
						'/sectors',
						false
					)} ${getMenuItemActive('/edit-sector', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/sectors'>
						<span className='menu-icon'>
							<WorkIcon />
						</span>
						<span className='menu-text'>Sectors</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/contacts',
						false
					)} ${getMenuItemActive('/view-contact', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/contacts'>
						<span className='menu-icon'>
							<AllInboxIcon />
						</span>
						<span className='menu-text'>Contacts</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/texts',
						false
					)} ${getMenuItemActive('/edit-text', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/texts'>
						<span className='menu-icon'>
							<DescriptionIcon />
						</span>
						<span className='menu-text'>Texts</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/formulas',
						false
					)} ${getMenuItemActive('/edit-formula', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/formulas'>
						<span className='menu-icon'>
							<EmojiSymbolsIcon />
						</span>
						<span className='menu-text'>Dropdowns for formulas</span>
					</NavLink>
				</li>
			</ul>

			{/* end::Menu Nav */}
		</>
	);
}
