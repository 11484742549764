import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { buttonsStyle, booleanFormatter, starFormatter } from '../../../components/tables/table'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getProducts, syncProducts, updateProduct } from '../../../../api/product'
import { Button, Tooltip } from '@material-ui/core'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { alertError, alertSuccess, alertLoading } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'

function getData(products) {
  let data = []
  for (let i = 0; i < products.length; ++i) {
      const elem = {}
      elem.name = products[i].name
      elem.sku = products[i].sku || '---'
      elem.price = products[i].price || '---'
      elem.categories = products[i].categories?.map(item => item.name).join(', ') || '---'
      elem.tags = products[i].tags?.map(item => item.name).join(', ') || '---'
      elem.featured = products[i].featured
      elem.active = products[i].active
      elem.id = products[i]._id
      data = data.concat(elem)
  }
  return data
}

export default function ProductsPage() {
  const [data, setData] = useState([])
  const [product, setProduct] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    const elem = data.find(item => item.id === cell)
    return (<>
      <Tooltip title="View">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/view-product/' + cell)}>
          <VisibilityIcon/>
        </Button>
      </Tooltip>
      <Tooltip title={elem?.active ? "Disable" : "Enable"}>
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setProduct(elem)
            setOpenConfirmDialog(true)
          }}>
          {elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon/>}
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'name', text: 'Name', sort: true },
    { dataField: 'sku', text: 'SKU', sort: true },
    { dataField: 'price', text: 'Price', sort: true },
    { dataField: 'categories', text: 'Categories', sort: true },
    { dataField: 'tags', text: 'Tags', sort: true },
    { dataField: 'featured', text: 'Featured', align: 'center', headerAlign: 'center', formatter: starFormatter, sort: true },
    { dataField: 'active', text: 'Active', align: 'center', headerAlign: 'center', formatter: booleanFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getProducts().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get products.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Products list'>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                alertLoading({ title: 'Getting all products...'})
                syncProducts().then(res => {
                  if (res.status === 200) {
                    alertSuccess({ title: 'Done!', customMessage: 'All products have been imported successfully.' })
                    setRefresh(true)
                  }
                }).catch(error => {
                  alertError({ error: error, customMessage: 'Could not sync products.' })
                })
              }}
            >
              Sync products
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={`Are you sure you want to ${product?.active ? 'disable' : 'enable'} this product?`}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              updateProduct(product.id, !product.active).then(res => {
                if (res.status === 204 || res.status === 200) {
                  alertSuccess({ title: `${product.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Product ${product.active ? 'disabled' : 'enabled'} successfully` })
                  history.push('/products')
                }
              }).catch(error => {
                alertError({ error: error, customMessage: `Could not ${product.active ? 'disable' : 'enable'} product.` })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
