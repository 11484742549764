
export const authActions = {
	loginActionSuccess,
	loginActionFailure,
	getLanguagesSuccess,
	getLanguagesFailure,
	logoutAction
}

// LOGIN

export const userConstants = {
	LOGIN_FAILURE: 'login-failure',
	LOGIN_SUCCESS: 'login-success',
	LOGOUT: 'logout'
}

export const languageConstants = {
	LANGUAGES_FAILURE: 'languages-failure',
	LANGUAGES_SUCCESS: 'languages-success'
}

function loginActionFailure ()  {
	return {
		type: userConstants.LOGIN_FAILURE
	}
}

function loginActionSuccess (user)  {
	return {
		type: userConstants.LOGIN_SUCCESS,
		user
	}
}

function getLanguagesSuccess (languages) {
	return {
		type: languageConstants.LANGUAGES_SUCCESS,
		languages
	}
}

function getLanguagesFailure () {
	return {
		type: languageConstants.LANGUAGES_FAILURE
	}
}

// LOGOUT

function logoutAction ()  {
	return {
		type: userConstants.LOGOUT
	}
}