import { authClient, API } from '../index'

// Count all contacts
export const countContacts = () => {
  return authClient().get(`${API}/contact/count`)
}

// Get all contacts
export const getContacts = () => {
  return authClient().get(`${API}/contact`)
}

// Get contact by id
export const getContactById = (id) => {
  return authClient().get(`${API}/contact/${id}`)
}