import { authClient, API } from '../index'
import { uploadFilesGetLinks } from "../files"

// Count all crops
export const countCrops = () => {
  return authClient().get(`${API}/crop/count`)
}

// Get all crops
export const getCrops = () => {
  return authClient().get(`${API}/crop?getDisabled=true`)
}

// Get crop by id
export const getCropById = (id) => {
  return authClient().get(`${API}/crop/${id}`)
}

// Delete crop
export const deleteCrop = (id) => {
  return authClient().delete(`${API}/crop/${id}`)
}

// Update crop
export const updateCrop = async (id, crop, files) => {
    if (files) {
      const filesURLs = await uploadFilesGetLinks(files, 'crops')
      crop.filesURLs = filesURLs
    }
    return authClient().put(`${API}/crop/${id}`, crop)
}

// Save crop
export const postCrop = async (crop, files) => {
    if (files) {
      const filesURLs = await uploadFilesGetLinks(files, 'crops')
      crop.filesURLs = filesURLs
    }
    return authClient().post(`${API}/crop`, crop)
}
