import { authClient, API } from '../index'

// Count all crop types
export const countCropTypes = () => {
  return authClient().get(`${API}/crop-type/count`)
}

// Get all crop types
export const getCropTypes = () => {
  return authClient().get(`${API}/crop-type?getDisabled=true`)
}

// Get crop type by id
export const getCropTypeById = (id) => {
  return authClient().get(`${API}/crop-type/${id}`)
}

// Delete crop type
export const deleteCropType = (id) => {
  return authClient().delete(`${API}/crop-type/${id}`)
}

// Update crop type
export const updateCropType = async (id, cropType) => {
    return authClient().put(`${API}/crop-type/${id}`, cropType)
}

// Save crop type
export const postCropType = async (cropType) => {
    return authClient().post(`${API}/crop-type`, cropType)
}
