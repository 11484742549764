import { authClient, API } from '../index'

// Count all categories
export const countCategories = () => {
  return authClient().get(`${API}/category/count`)
}

// Sync all categories
export const syncCategories = () => {
  return authClient().get(`${API}/category/sync`)
}

// Sync all post categories
export const syncPostCategories = () => {
  return authClient().get(`${API}/category/sync-post`)
}

// Get all categories
export const getCategories = () => {
  return authClient().get(`${API}/category?getEmpty=true`)
}

// Get category by id
export const getCategoryById = (id) => {
  return authClient().get(`${API}/category/${id}`)
}

// Update category
export const updateCategory = async (id, active) => {
    return authClient().put(`${API}/category/${id}`, { active })
}