import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import {
  Button,
  TextField
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { getUserById } from "../../../../api/user"
import { useSkeleton } from "../../../hooks/useSkeleton"
import { alertError } from "../../../../utils/logger"
import { getLanguages } from '../../../../api/language'

export default function ViewClientsPage() {
  const [user, setUser] = useState({})
  const userId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!userId) {
      disableLoadingData()
      history.push('/users')
    }
    getLanguages(userId).then(resp => {
      if (resp.status === 200) {
        getUserById(userId).then(res => {
          if (res.status === 200) {
            const user = { ...res.data, language: resp.data.find(item => item.isocode === res.data.language)?.fullName || '----' }
            setUser(user)
            disableLoadingData()
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get user.' })
          history.push('/users')
        })
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get user.' })
      history.push('/users')
    })
  }, [userId, disableLoadingData, history])


  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='View App user'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Full name"
            value={user.fullName}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`email`}
            label="Email"
            value={user.email}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`roles`}
            label="Wordpress roles"
            value={user.wordpress_roles.join(', ')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`language`}
            label="Language"
            value={user.language}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/users')}
        variant="outlined">
        Back
      </Button>
    </>
  );
}
