import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../../_metronic/_partials/controls'
import Table, { buttonsStyle, longTextFormatter, booleanFormatter } from '../../../../../components/tables/table'
import ConfirmDialog from '../../../../../components/dialogs/ConfirmDialog'
import { getAttributes } from '../../../../../../api/attribute'
import { getTerms, updateTerm, syncTerms } from '../../../../../../api/term'
import { Button, Tooltip } from '@material-ui/core'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import VisibilityIcon from '@material-ui/icons/Visibility'
import { alertError, alertSuccess, alertLoading } from '../../../../../../utils/logger'
import { useHistory } from 'react-router-dom'

function getData(attributeTerms, attributes) {
  let data = []
  for (let i = 0; i < attributeTerms.length; ++i) {
      const elem = {}
      elem.name = attributeTerms[i].name
      elem.description = attributeTerms[i].description || '---'
      elem.quantity = attributeTerms[i].count
      elem.parent = attributes.find(item => item.wordpress_id === attributeTerms[i].parent)?.name || '---'
      elem.active = attributeTerms[i].active
      elem.id = attributeTerms[i]._id
      data = data.concat(elem)
  }
  return data
}

export default function AttributeTermsPage() {
  const [data, setData] = useState([])
  const [attributeTerm, setAttributeTerm] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    const elem = data.find(item => item.id === cell)
    return (<>
      <Tooltip title="View">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/product-view-term-attribute/' + cell)}>
          <VisibilityIcon/>
        </Button>
      </Tooltip>
      <Tooltip title={elem?.active ? "Disable" : "Enable"}>
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setAttributeTerm(elem)
            setOpenConfirmDialog(true)
          }}>
          {elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon/>}
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'name', text: 'Name', sort: true },
    { dataField: 'description', text: 'Description', formatter: longTextFormatter, sort: true },
    { dataField: 'quantity', text: 'Quantity', sort: true },
    { dataField: 'parent', text: 'Attribute', sort: true },
    { dataField: 'active', text: 'Active', align: 'center', headerAlign: 'center', formatter: booleanFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getAttributes().then((resp) => {
      if (resp.status === 200) {
        getTerms().then((res) => {
          if (res.status === 200) {
            setData(getData(res.data, resp.data))
            setRefresh(false)
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get attribute term.' })
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get attributes.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Attribute terms list'>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                alertLoading({ title: 'Getting all attribute terms...'})
                syncTerms().then(res => {
                  if (res.status === 200) {
                    alertSuccess({ title: 'Done!', customMessage: 'All attribute terms have been imported successfully.' })
                    setRefresh(true)
                  }
                }).catch(error => {
                  alertError({ error: error, customMessage: 'Could not sync attribute terms.' })
                })
              }}
            >
              Sync attribute terms
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={`Are you sure you want to ${attributeTerm?.active ? 'disable' : 'enable'} this attribute term?`}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              updateTerm(attributeTerm.id, !attributeTerm.active).then(res => {
                if (res.status === 204 || res.status === 200) {
                  alertSuccess({ title: `${attributeTerm.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Attribute term ${attributeTerm.active ? 'disabled' : 'enabled'} successfully` })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: `Could not ${attributeTerm.active ? 'disable' : 'enable'} attribute term.` })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
