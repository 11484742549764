import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteCropType, getCropTypeById, postCropType, updateCropType } from '../../../../../api/cropType'
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from '../../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../../utils/logger'
import Editor from '../../../../components/editor/Editor'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

function getEmptyCropType() {
  return {
    name: '',
    description: '',
    irrigationFeatures: '',
    active: true
  }
}

export default function EditCropTypesPage() {
  const [cropType, setCropType] = useState(getEmptyCropType())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const cropTypeId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!cropTypeId) {
      disableLoadingData()
      return
    }
    getCropTypeById(cropTypeId).then(res => {
      if (res.status === 200) {
        setCropType(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get type of crop.' })
      history.push('/types-crops')
    })
  }, [cropTypeId, disableLoadingData, history])

  function saveCropType() {
    if (!cropTypeId) {
      postCropType(cropType).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Type of crop saved successfully.' })
          history.push('/types-crops')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save type of crop.' })
      })
    } else {
      updateCropType(cropTypeId, cropType).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/types-crops')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setCropType({ ...cropType, [element]: event.target.value })
  }

  const handleChangeEditor = (element, value) => {
    setCropType({ ...cropType, [element]: value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit type of crop'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`name`}
            label="Name"
            value={cropType.name || ''}
            onChange={handleChange('name')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <Editor
            body={cropType.description || ''}
            setBody={new_body => handleChangeEditor('description', new_body)}
            className='max-height'
            placeholder={'Enter description here...'}
          />
          <br/>
          <Editor
            body={cropType.irrigationFeatures || ''}
            setBody={new_body => handleChangeEditor('irrigationFeatures', new_body)}
            className='max-height'
            placeholder={'Enter irrigation features here...'}
          />
          <br/>
          <FormControlLabel
            control={<Checkbox checked={cropType.active} onChange={() => setCropType({ ...cropType, active: !cropType.active })} name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/types-crops')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveCropType()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save type of crop
      </Button>
      {cropTypeId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete type of crop
          </Button>
        </MuiThemeProvider>
      </>}
      <ConfirmDialog
        title={'Are you sure you want to delete this type of crop?'}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          deleteCropType(cropTypeId).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: 'Deleted!', customMessage: 'Type of crop deleted successfully' })
              history.push('/types-crops')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: 'Could not delete type of crop.' })
          })
        }}
      />
    </>
  );
}
