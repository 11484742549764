import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls'
import {
	Button,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel,
  TextField
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { getTagById, updateTag } from '../../../../../api/tag'
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from '../../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../../utils/logger'
import Editor from '../../../../components/editor/Editor'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

export default function ViewPostTagsPage() {
  const [tag, setTag] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const tagId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!tagId) {
      disableLoadingData()
      history.push('/post-tags')
      return
    }
    getTagById(tagId).then(res => {
      if (res.status === 200) {
        setTag(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get tag.' })
      history.push('/post-tags')
    })
  }, [tagId, disableLoadingData, history])

  if (isLoadingData || !tag)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='View tag'></CardHeader>
        <CardBody>
          <TextField
            id={`name`}
            label="Name"
            value={tag.name}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`slug`}
            label="Slug"
            value={tag.slug}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />        
          <br/>
          <Editor
            body={tag.description}
            className='max-height'
            disabled
            label="Description"
          />
          <TextField
            id={`count`}
            label="Quantity"
            value={tag.count}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={tag.active} disabled name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/post-tags')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <MuiThemeProvider theme={theme}>
        <Button
          onClick={() => setOpenConfirmDialog(true)}
          variant="outlined"
          color={tag.active ? "secondary" : "primary"}>
          {`${tag.active ? 'Disable' : 'Enable'} tag`}
        </Button>
      </MuiThemeProvider>
      <ConfirmDialog
        title={`Are you sure you want to ${tag.active ? 'disable' : 'enable'} this tag?`}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          updateTag(tagId, !tag.active).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: `${tag.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Tag ${tag.active ? 'disabled' : 'enabled'} successfully` })
              history.push('/post-tags')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: `Could not ${tag.active ? 'disable' : 'enable'} tag.` })
          })
        }}
      />
    </>
  );
}