import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
  TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { getPostById, updatePost } from '../../../../api/post'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import Editor from '../../../components/editor/Editor'
import { getCategories } from '../../../../api/category'
import { getTags } from '../../../../api/tag'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

export default function ViewPostsPage() {
  const [post, setPost] = useState(null)
  const [categories, setCategories] = useState(null)
  const [tags, setTags] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const postId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    getCategories().then(res => {
      if (res.status === 200) {
        setCategories(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get post categories.' })
      history.push('/posts')
    })
    getTags().then(res => {
      if (res.status === 200) {
        setTags(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get post tags.' })
      history.push('/posts')
    })
    if (!postId) {
      disableLoadingData()
      history.push('/posts')
      return
    }
    getPostById(postId).then(res => {
      if (res.status === 200) {
        setPost(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get post.' })
      history.push('/posts')
    })
  }, [postId, disableLoadingData, history])

  if (isLoadingData || !post)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='View post'></CardHeader>
        <CardBody>
          <Editor
            body={post.title?.rendered}
            disabled
            label="Title"
          />
          <TextField
            id={`slug`}
            label="Slug"
            value={post.slug}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />       
          <br/>
          <Editor
            body={post.content?.rendered}
            className='max-height'
            disabled
            label="Content"
          />
          <br/>
          <TextField
            id={`categories`}
            label="Categories"
            value={post.categories?.map(item => categories.find(cat => cat.wordpress_id === item.toString() && cat.isPostCategory)?.name || '---').join(', ') || '---'}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          <TextField
            id={`tags`}
            label="Tags"
            value={post.tags?.map(item => tags.find(tag => tag.wordpress_id === item.toString() && tag.isPostTag)?.name || '---').join(', ') || '---'}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            disabled
          />
          {/* TODO: DISPLAY FEATURED IMAGE?? */}
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={post.active} disabled name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/posts')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <MuiThemeProvider theme={theme}>
        <Button
          onClick={() => setOpenConfirmDialog(true)}
          variant="outlined"
          color={post.active ? "secondary" : "primary"}>
          {`${post.active ? 'Disable' : 'Enable'} post`}
        </Button>
      </MuiThemeProvider>
      <ConfirmDialog
        title={`Are you sure you want to ${post.active ? 'disable' : 'enable'} this post?`}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          updatePost(postId, !post.active).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: `${post.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Post ${post.active ? 'disabled' : 'enabled'} successfully` })
              history.push('/posts')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: `Could not ${post.active ? 'disable' : 'enable'} post.` })
          })
        }}
      />
    </>
  );
}