import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { buttonsStyle, booleanFormatter, dateFormatter } from '../../../components/tables/table'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getPosts, updatePost, syncPosts } from '../../../../api/post'
import { Button, Tooltip } from '@material-ui/core'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import VisibilityIcon from '@material-ui/icons/Visibility'
import { alertError, alertSuccess, alertLoading } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'
import { getCategories } from '../../../../api/category'
import { getTags } from '../../../../api/tag'

function getData(posts, categories, tags) {
  let data = []
  for (let i = 0; i < posts.length; ++i) {
      const elem = {}
      elem.title = posts[i].title?.rendered || '----'
      elem.categories = posts[i].categories?.map(item => categories.find(cat => cat.wordpress_id === item.toString() && cat.isPostCategory)?.name || '---').join(', ') || '---'
      elem.tags = posts[i].tags?.map(item => tags.find(tag => tag.wordpress_id === item.toString() && tag.isPostTag)?.name || '---').join(', ') || '---'
      elem.date = posts[i].date
      elem.active = posts[i].active
      elem.id = posts[i]._id
      data = data.concat(elem)
  }
  return data
}

export default function PostsPage() {
  const [data, setData] = useState([])
  const [post, setPost] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    const elem = data.find(item => item.id === cell)
    return (<>
      <Tooltip title="View">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/view-post/' + cell)}>
          <VisibilityIcon/>
        </Button>
      </Tooltip>
      <Tooltip title={elem?.active ? "Disable" : "Enable"}>
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setPost(elem)
            setOpenConfirmDialog(true)
          }}>
          {elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon/>}
        </Button>
      </Tooltip>
    </>)
  }
  
  function  htmlFormatter(cell) {
    return <div dangerouslySetInnerHTML={{ __html: cell }} />
  }
  
  const columns = [
    { dataField: 'title', text: 'Title', sort: true, formatter: htmlFormatter },
    { dataField: 'categories', text: 'Categories', sort: true },
    { dataField: 'tags', text: 'Tags', sort: true },
    { dataField: 'date', text: 'Date', sort: true, formatter: dateFormatter },
    { dataField: 'active', text: 'Active', align: 'center', headerAlign: 'center', formatter: booleanFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getCategories().then(response => {
      if (response.status === 200) {
        getTags().then(resp => {
          if (resp.status === 200) {
            getPosts().then((res) => {
              if (res.status === 200) {
                setData(getData(res.data, response.data, resp.data))
                setRefresh(false)
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not get posts.' })
            })
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get post tags.' })
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get post categories.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Blog posts list'>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                alertLoading({ title: 'Getting all posts...'})
                syncPosts().then(res => {
                  if (res.status === 200) {
                    alertSuccess({ title: 'Done!', customMessage: 'All posts have been imported successfully.' })
                    setRefresh(true)
                  }
                }).catch(error => {
                  alertError({ error: error, customMessage: 'Could not sync posts.' })
                })
              }}
            >
              Sync posts
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={`Are you sure you want to ${post?.active ? 'disable' : 'enable'} this post?`}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              updatePost(post.id, !post.active).then(res => {
                if (res.status === 204 || res.status === 200) {
                  alertSuccess({ title: `${post.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Post ${post.active ? 'disabled' : 'enabled'} successfully` })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: `Could not ${post.active ? 'disable' : 'enable'} post.` })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
