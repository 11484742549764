import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls"
import Table, { dateFormatter, buttonsStyle } from '../../../components/tables/table'
import { getUsers, syncUsers } from "../../../../api/user"
import { Button, Tooltip } from "@material-ui/core"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { alertError, alertLoading, alertSuccess } from "../../../../utils/logger"
import { useHistory } from 'react-router-dom'
import { getLanguages } from '../../../../api/language'

function getData(users, languages) {
  let data = []
  for (let i = 0; i < users.length; ++i) {
    if (users[i].role === 'wordpress-user') {
      const elem = {}
      elem.name = users[i].fullName
      elem.email = users[i].email
      elem.roles = users[i]['wordpress_roles'].join(', ')
      elem.language = languages.find(item => item.isocode === users[i].language)?.fullName || '----'
      elem.createdAt = users[i].createdAt
      elem.id = users[i]._id
      data = data.concat(elem)
    }
  }
  return data
}

export default function UsersPage() {
  const [data, setData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="View">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/view-user/' + cell)}>
          <VisibilityIcon/>
        </Button>
      </Tooltip>
    </>)
  }


  const columns = [
    { dataField: 'name', text: 'Full name', sort: true },
    { dataField: 'email', text: 'Email', sort: true },
    { dataField: 'roles', text: 'Wordpress roles', sort: true },
    { dataField: 'language', text: 'Language', sort: true },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getLanguages().then((resp) => {
      if (resp.status === 200) {
        getUsers().then((res) => {
          if (res.status === 200) {
            setData(getData(res.data, resp.data))
            setRefresh(false)
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get users.' })
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get languages.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='App Users list'>
          <CardHeaderToolbar>
          <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  alertLoading({ title: 'Getting all users...'})
                  syncUsers().then(res => {
                    if (res.status === 200) {
                      alertSuccess({ title: 'Done!', customMessage: 'All users have been imported successfully.' })
                      setRefresh(true)
                    }
                  }).catch(error => {
                    alertError({ error: error, customMessage: 'Could not sync users.' })
                  })
                }}
              >
                Sync users
              </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
        </CardBody>
      </Card>
    </>
  );
}
