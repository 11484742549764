import { authClient, API } from '../index'

// Count all sectors
export const countSectors = () => {
  return authClient().get(`${API}/sector/count`)
}

// Get all sectors
export const getSectors = () => {
  return authClient().get(`${API}/sector?getDisabled=true`)
}

// Get sector by id
export const getSectorById = (id) => {
  return authClient().get(`${API}/sector/${id}`)
}

// Delete sector
export const deleteSector = (id) => {
  return authClient().delete(`${API}/sector/${id}`)
}

// Create sector
export const postSector = (sector) => {
  return authClient().post(`${API}/sector`, sector)
}

// Update sector
export const updateSector = (id, sector) => {
  return authClient().put(`${API}/sector/${id}`, sector)
}
