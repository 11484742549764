import { authClient, API } from '../index'

// Count all formulas
export const countFormulas = () => {
  return authClient().get(`${API}/formula/count`)
}

// Get all formulas
export const getFormulas = () => {
  return authClient().get(`${API}/formula?getDisabled=true`)
}

// Get formula by id
export const getFormulaById = (id) => {
  return authClient().get(`${API}/formula/${id}`)
}

// Delete formula
export const deleteFormula = (id) => {
  return authClient().delete(`${API}/formula/${id}`)
}

// Create formula
export const postFormula = (formula) => {
  return authClient().post(`${API}/formula`, formula)
}

// Update formula
export const updateFormula = (id, formula) => {
  return authClient().put(`${API}/formula/${id}`, formula)
}
