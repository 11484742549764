import React, { useEffect, useState } from 'react';
import { alertError } from '../../utils/logger';
//import { countLanguages } from '../../api/language';
import { countAdmins, countClients } from '../../api/user';
import { countContacts } from '../../api/contact';
import { countProducts } from '../../api/product';
import { countCategories } from '../../api/category';
import { countTags } from '../../api/tag';
import { countAttributes } from '../../api/attribute';
import { countTerms } from '../../api/term';
import { countCrops } from '../../api/crop';
import { countCropTypes } from '../../api/cropType';
import { countTexts } from '../../api/text';
import { countSectors } from '../../api/sector';
import { useHistory } from 'react-router-dom';
import { countFormulas } from '../../api/formula';
import { countPosts } from '../../api/post';

export function DashboardPage() {
	const [data, setData] = useState([]);
	const history = useHistory()

	useEffect(() => {
		async function fetchData() {
			try {
				const clients = await countClients()
				//const languages = await countLanguages()
				const admins = await countAdmins()
				const contacts = await countContacts()
				const texts = await countTexts()
				const products = await countProducts()
				const categories = await countCategories()
				const tags = await countTags()
				const attributes = await countAttributes()
				const terms = await countTerms()
				const crops = await countCrops()
				const cropTypes = await countCropTypes()
				const sectors = await countSectors()
				const formulas = await countFormulas()
				const posts = await countPosts()

				setData({
					numProducts: products.data,
					numCategories: categories.data,
					numTags: tags.data,
					numAttributes: attributes.data,
					numTerms: terms.data,
					numCrops: crops.data,
					numCropTypes: cropTypes.data,
					numClients: clients.data,
					numAdmins: admins.data,
					//numLanguages: languages.data,
					numContacts: contacts.data,
					numTexts: texts.data,
					numSectors: sectors.data,
					numFormulas: formulas.data,
					numPosts: posts.data
				})
			} catch (err) {
				alertError({ error: err, customMessage: 'Could not get statistics.'})
			}
		}
		fetchData()
	}, []);

	return (
		<>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/products')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Products</h5>
								<div className='contentDash'>{data.numProducts}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/product-categories')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Categories</h5>
								<div className='contentDash'>{data.numCategories}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/product-tags')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Tags</h5>
								<div className='contentDash'>{data.numTags}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/product-attributes')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Attributes</h5>
								<div className='contentDash'>{data.numAttributes}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/product-terms-attributes')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Attribute terms</h5>
								<div className='contentDash'>{data.numTerms}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/crops')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Crops</h5>
								<div className='contentDash'>{data.numCrops}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/crop-types')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Types of crops</h5>
								<div className='contentDash'>{data.numCropTypes}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/users')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>App users</h5>
								<div className='contentDash'>{data.numClients}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/admins')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Administrators</h5>
								<div className='contentDash'>{data.numAdmins}</div>
							</div>
						</div>
					</div>
					{/*<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/languages')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Languages</h5>
								<div className='contentDash'>{data.numLanguages}</div>
							</div>
						</div>
					</div>*/}
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/posts')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Blog posts</h5>
								<div className='contentDash'>{data.numPosts}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/sectors')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Sectors</h5>
								<div className='contentDash'>{data.numSectors}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/contacts')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Contacts</h5>
								<div className='contentDash'>{data.numContacts}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/texts')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Texts</h5>
								<div className='contentDash'>{data.numTexts}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/formulas')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>Dropdowns for formulas</h5>
								<div className='contentDash'>{data.numFormulas}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
