import { authClient, API } from '../index'

// Count all products
export const countProducts = () => {
  return authClient().get(`${API}/product/count`)
}

// Sync all products
export const syncProducts = () => {
  return authClient().get(`${API}/product/sync`)
}

// Get all products
export const getProducts = () => {
  return authClient().get(`${API}/product?getDisabled=true`)
}

// Get product by id
export const getProductById = (id) => {
  return authClient().get(`${API}/product/${id}`)
}

// Update product
export const updateProduct = async (id, active) => {
    return authClient().put(`${API}/product/${id}`, { active })
}