import { authClient, API } from '../index'

// Count all tags
export const countTags = () => {
  return authClient().get(`${API}/tag/count`)
}

// Sync all tags
export const syncTags = () => {
  return authClient().get(`${API}/tag/sync`)
}

// Sync all post tags
export const syncPostTags = () => {
  return authClient().get(`${API}/tag/sync-post`)
}

// Get all tags
export const getTags = () => {
  return authClient().get(`${API}/tag?getEmpty=true`)
}

// Get tag by id
export const getTagById = (id) => {
  return authClient().get(`${API}/tag/${id}`)
}

// Update tag
export const updateTag = async (id, active) => {
    return authClient().put(`${API}/tag/${id}`, { active })
}