import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteCrop, getCropById, postCrop, updateCrop } from '../../../../api/crop'
import { getCropTypes } from '../../../../api/cropType'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import { useSkeleton } from '../../../hooks/useSkeleton'
import { SERVER_URL } from "../../../../api"
import { alertError, alertSuccess } from '../../../../utils/logger'
import { buttonsStyle } from '../../../components/tables/table'
import { ArrowUpward, ArrowDownward, Visibility } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import Editor from '../../../components/editor/Editor'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};


function getEmptyCrop() {
  return {
    name: '',
    description: '',
    irrigation: '',
    floor: '',
    climate: '',
    sowing: '',
    properties: '',
    additionalInfo: '',
    filesURLs: [],
    cropType: null,
    active: true
  }
}

export default function EditCropsPage() {
  const [crop, setCrop] = useState(getEmptyCrop())
  const [cropTypes, setCropTypes] = useState(null)
	const [selectedFiles, setSelectedFiles] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
  const cropId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    getCropTypes().then(resp => {
      if (resp.status === 200) {
        setCropTypes(resp.data)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get types of crops.' })
      history.push('/crops')
    })
    if (!cropId) {
      disableLoadingData()
      return
    }
    getCropById(cropId).then(res => {
      if (res.status === 200) {
        setCrop(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get crop.' })
      history.push('/crops')
    })
  }, [cropId, disableLoadingData, history])

  function saveCrop() {
    if (!cropId) {
      if (!selectedFiles) {
        alertError({ error: null, customMessage: 'Please upload at least one image or video.'})
      }
      postCrop(crop, selectedFiles).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Crop saved successfully.' })
          history.push('/crops')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save crop.' })
      })
    } else {
      updateCrop(cropId, crop, selectedFiles).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/crops')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setCrop({ ...crop, [element]: event.target.value })
  }

  const handleChangeEditor = (element, value) => {
    setCrop({ ...crop, [element]: value })
  }

  const handleDeleteFile = (index) => {
    // TODO: DELETE FILES FROM SERVER
    let newFiles = [...crop.filesURLs]
    /*const deletedFile = */newFiles.splice(index, 1)
    setCrop({ ...crop, filesURLs: newFiles })
    if (selectedFiles && selectedFiles[index]) {
      let _selectedFiles = {}
      for (const [key, value] of Object.entries(selectedFiles)) {
        if (key !== index) {
          if (key > index) {
            _selectedFiles[key - 1] = value
          } else _selectedFiles[key] = value
        } 
      }
      if (!Object.keys(_selectedFiles).length) _selectedFiles = null
      setSelectedFiles(_selectedFiles)
    } /*else setDeletedFiles(deletedFiles ? deletedFiles.concat(deletedFile) : [deletedFile])*/
  }

  const handleMoveFile = (index, newIndex) => {
    const aux = crop.filesURLs[index]
    let files = [...crop.filesURLs]
    files.splice(index, 1, files[newIndex])
    files.splice(newIndex, 1, aux)
    setCrop({ ...crop, filesURLs: files })
    if (selectedFiles && (selectedFiles[index] || selectedFiles[newIndex])) {
      let _selectedFiles = {}
      for (const [key, value] of Object.entries(selectedFiles)) {
        if (key === index.toString()) _selectedFiles[newIndex] = value
        else if (key === newIndex.toString()) _selectedFiles[index] = value
        else _selectedFiles[key] = value
      }
      setSelectedFiles(_selectedFiles)
    }
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit crop'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`name`}
            label="Name"
            value={crop.name || ''}
            onChange={handleChange('name')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <Editor
            body={crop.description || ''}
            setBody={new_body => handleChangeEditor('description', new_body)}
            className='max-height'
            placeholder={'Enter short description here...'}
          />
          <br/>
          <Editor
            body={crop.irrigation || ''}
            setBody={new_body => handleChangeEditor('irrigation', new_body)}
            className='max-height'
            placeholder={'Enter irrigation information here...'}
          />
          <br/>
          <Editor
            body={crop.floor || ''}
            setBody={new_body => handleChangeEditor('floor', new_body)}
            className='max-height'
            placeholder={'Enter floor information here...'}
          />
          <br/>
          <Editor
            body={crop.climate || ''}
            setBody={new_body => handleChangeEditor('climate', new_body)}
            className='max-height'
            placeholder={'Enter climate information here...'}
          />
          <br/>
          <Editor
            body={crop.sowing || ''}
            setBody={new_body => handleChangeEditor('sowing', new_body)}
            className='max-height'
            placeholder={'Enter sowing information here...'}
          />
          <br/>
          <Editor
            body={crop.properties || ''}
            setBody={new_body => handleChangeEditor('properties', new_body)}
            className='max-height'
            placeholder={'Enter crop properties here...'}
          />
          <br/>
          <Editor
            body={crop.additionalInfo || ''}
            setBody={new_body => handleChangeEditor('additionalInfo', new_body)}
            className='max-height'
            placeholder={'Enter additional info here...'}
          />
          <br/>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={crop.cropType || ''}
              onChange={handleChange('cropType')}
              MenuProps={MenuProps}
            >
              {cropTypes?.map((option) => (
                <MenuItem key={option._id} value={option._id}>{option.name|| '--'}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Select a type</FormHelperText>
          </FormControl>
          <br />
          <br />
          {crop.filesURLs?.map((_, index) => {
            return (
              <>
                <label htmlFor={"upload-file" + index}>
                  <input
                    style={{ display: 'none' }}
                    id={"upload-file" + index}
                    name={"upload-file" + index}
                    type="file"
                    accept={'image/*, video/*'}
                    onChange={(e) => {
                      setSelectedFiles({ ...selectedFiles, [index]: e.target.files[0] })
                    }}
                  />
                  <Button
                    style={{ marginRight: '15px' }}
                    color="secondary"
                    component="span"
                    variant="outlined">
                    {((selectedFiles && selectedFiles[index]) || crop.filesURLs[index] !== '') ? 'Change file ' + (index + 1) : 'Upload file ' + (index + 1)}
                  </Button>
                </label>
                {((selectedFiles && selectedFiles[index]) || crop.filesURLs[index] !== '') &&
                  <>
                    <Tooltip title={'Preview file ' + (index + 1)}>
                      <Button
                        size="small"
                        onClick={() => setOpenPreviewDialog(index)}
                        style={buttonsStyle}>
                        <Visibility/>
                      </Button>
                    </Tooltip>
                    <PreviewDialog
                      title={'Preview file ' + (index + 1)}
                      open={openPreviewDialog === index}
                      setOpen={setOpenPreviewDialog}
                      src={(selectedFiles && selectedFiles[index]) ? URL.createObjectURL(selectedFiles[index]) : `${SERVER_URL}/${crop.filesURLs[index]}`}
                    />
                    <Tooltip title="Delete">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        onClick={() => handleDeleteFile(index)}>
                        <DeleteIcon/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move up">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        disabled={index === 0}
                        onClick={() => handleMoveFile(index, index - 1)}>
                        <ArrowUpward/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move down">
                      <Button
                        size="small"
                        disabled={!(index < crop.filesURLs.length - 1 && ((selectedFiles && selectedFiles[index + 1]) || crop.filesURLs[index + 1] !== ''))}
                        style={{...buttonsStyle, marginRight: '1em' }}
                        onClick={() => handleMoveFile(index, index + 1)}>
                        <ArrowDownward/>
                      </Button>
                    </Tooltip>
                    <span>
                      {selectedFiles && selectedFiles[index] ? selectedFiles[index]?.name : (crop.filesURLs[index] !== '' ? crop.filesURLs[index].split(/-(.*)/s)[1] : '')}
                    </span>
                    <br />
                  </>
                }
              </>
            )
          })}
          <br />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setCrop({ ...crop, filesURLs: crop.filesURLs.concat('') })}
          >
            Add file
          </Button>
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={crop.active} onChange={() => setCrop({ ...crop, active: !crop.active })} name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/crops')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveCrop()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save crop
      </Button>
      {cropId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete crop
          </Button>
        </MuiThemeProvider>
      </>}
      <ConfirmDialog
        title={'Are you sure you want to delete this crop?'}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          deleteCrop(cropId).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: 'Deleted!', customMessage: 'Crop deleted successfully' })
              history.push('/crops')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: 'Could not delete crop.' })
          })
        }}
      />
    </>
  );
}
