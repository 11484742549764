import { authClient, API } from '../index'

// Count all attributes
export const countAttributes = () => {
  return authClient().get(`${API}/attribute/count`)
}

// Sync all attributes
export const syncAttributes = () => {
  return authClient().get(`${API}/attribute/sync`)
}

// Get all attributes
export const getAttributes = () => {
  return authClient().get(`${API}/attribute?getDisabled=true`)
}

// Get attribute by id
export const getAttributeById = (id) => {
  return authClient().get(`${API}/attribute/${id}`)
}

// Update attribute
export const updateAttribute = async (id, active) => {
    return authClient().put(`${API}/attribute/${id}`, { active })
}