import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel,
  Grid,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteFormula, getFormulaById, getFormulas, postFormula, updateFormula } from '../../../../api/formula'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import DeleteIcon from '@material-ui/icons/Delete'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getEmptyFormula() {
  return {
    name: '',
    options: [],
    depends_on: null,
    active: true
  }
}

export default function EditFormulasPage() {
  const [formula, setFormula] = useState(getEmptyFormula())
  const [selectedDropdown, setSelectedDropdown] = useState(null)
  const [dropdowns, setDropdowns] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const formulaId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    getFormulas().then(res => {
      if (res.status === 200) {
        setDropdowns(res.data)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get dropdowns for formulas.' })
      history.push('/formulas')
    })
    if (!formulaId) {
      disableLoadingData()
      return
    }
    getFormulaById(formulaId).then(res => {
      if (res.status === 200) {
        setFormula(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get dropdown for formula.' })
      history.push('/formulas')
    })
  }, [formulaId, disableLoadingData, history])

  function saveFormula() {
    if (!formulaId) {
      postFormula(formula).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Dropdown successfully created.' })
          history.push('/formulas')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save dropdown for formula.' })
      })
    } else {
      updateFormula(formulaId, formula).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/formulas')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, index, field) => (event) => {
    if (element === 'depends_on') setSelectedDropdown(dropdowns.find(item => item._id === event.target.value))
    if (index || index === 0) {
      if (event.target.value === ' ') return
      if (!formula[element]) formula[element] = {}
      let newText = formula[element]
      if (field) newText[index][field] = event.target.value
      else newText[index] = event.target.value
      setFormula({ ...formula, [element]: newText })
    } else setFormula({ ...formula, [element]: event.target.value })
  }

  const removeOption = (index) => {
    let _options = formula.options
    _options.splice(index, 1)
    setFormula({ ...formula, options: _options })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit dropdown for formula'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`name`}
            label="Name"
            value={formula.name}
            onChange={handleChange('name')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`options_description`}
            label="Options description"
            value={formula.options_description}
            onChange={handleChange('options_description')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-standard-label">Depends on</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={formula.depends_on || ''}
              onChange={handleChange('depends_on')}
              MenuProps={MenuProps}
            >
              {dropdowns?.map((option) => (
                <MenuItem key={option._id} value={option._id}>{option.name || '--'}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Select a dropdown for formula</FormHelperText>
          </FormControl>
          <br />
          <br />
          {formula.options?.map((option, index) => (
            <Grid
              key={`options-${index}`}
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
              className="mb-5"
              spacing={2}
            >
              <Grid item xs={5}>
                <TextField
                  id={`options-label-${index}`}
                  label={`Option label ${index + 1}`}
                  value={option.label}
                  onChange={handleChange('options', index, 'label')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id={`options-value-${index}`}
                  label={`Option value ${index + 1}`}
                  value={option.value}
                  onChange={handleChange('options', index, 'value')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid style={{ display: 'flex', alignItems: 'center'}}>
                <MuiThemeProvider theme={theme}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      if (formula.options.length > 1) removeOption(index)
                      else {
                        alertError({ error: null, customMessage: 'At least one option is required.' })
                      }
                    }}
                  >
                    <DeleteIcon/>
                  </Button>
                </MuiThemeProvider>
              </Grid>
              {formula.depends_on && <Grid item xs={5}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-standard-label">{`Option ${index + 1} depends on`}</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={option.value_depends_on || ''}
                    onChange={handleChange('options', index, 'value_depends_on')}
                    MenuProps={MenuProps}
                  >
                    {(selectedDropdown || dropdowns.find(item => item._id === formula.depends_on))?.options?.map((option) => (
                      <MenuItem key={option.value} value={option.label}>{option.label || '--'}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Select a value from the dropdown for formula</FormHelperText>
                </FormControl>
              </Grid>}
            </Grid>
          ))}
          <Button
            onClick={() => setFormula({...formula, options: formula.options.concat({})})}
            variant="outlined"
            color="primary">
            Add option
          </Button>
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={formula.active} onChange={() => setFormula({ ...formula, active: !formula.active })} name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/formulas')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveFormula()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save dropdown
      </Button>
      {formulaId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete dropdown
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this dropdown?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteFormula(formulaId).then(res => {
              if (res.status === 204 || res.status === 200) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Dropdown deleted successfully' })
                history.push('/formulas')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete dropdown.' })
            })
          }}
        />
      </>}
    </>
  );
}
