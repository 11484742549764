import { authClient, API } from '../index'

// Count all admins
export const countAdmins = () => {
  return authClient().get(`${API}/user/count-admins`)
}

// Count all clients
export const countClients = () => {
  return authClient().get(`${API}/user/count`)
}

// Get all users
export const getUsers = () => {
  return authClient().get(`${API}/user`)
}

// Get user by id
export const getUserById = (id) => {
  return authClient().get(`${API}/user/${id}`)
}

// Delete user
export const deleteUser = (id) => {
  return authClient().delete(`${API}/user/${id}`)
}

// Create user
export const postUser = async (user, file) => {
  return authClient().post(`${API}/user`, user)
}

// Update user
export const updateUser = async (id, user, file) => {
  return authClient().put(`${API}/user/${id}`, user)
}

// Sync all users
export const syncUsers = () => {
  return authClient().get(`${API}/user/sync`)
}

// Update user notifications
export const updateUserNotifications = async (id, notifications) => {
  return authClient().put(`${API}/user/notifications/${id}`, notifications)
}
