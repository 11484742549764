import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute } from '../_metronic/layout';
import { DashboardPage } from './pages/DashboardPage';
import AdminsPage from './modules/ContentManager/admins/AdminsPage';
import EditAdminsPage from './modules/ContentManager/admins/EditAdminsPage';
import TextsPage from './modules/ContentManager/texts/TextsPage';
import EditTextsPage from './modules/ContentManager/texts/EditTextsPage';
//import LanguagesPage from './modules/ContentManager/languages/LanguagesPage'
//import EditLanguagesPage from './modules/ContentManager/languages/EditLanguagesPage'
import UsersPage from './modules/ContentManager/users/UsersPage';
import ViewUsersPage from './modules/ContentManager/users/ViewUsersPage';
import ContactsPage from './modules/ContentManager/contacts/ContactsPage';
import ViewContactsPage from './modules/ContentManager/contacts/ViewContactsPage';
import ProductsPage from './modules/ContentManager/products/ProductsPage';
import ViewProductsPage from './modules/ContentManager/products/ViewProductsPage';
import CategoriesPage from './modules/ContentManager/products/categories/CategoriesPage';
import ViewCategoriesPage from './modules/ContentManager/products/categories/ViewCategoriesPage';
import TagsPage from './modules/ContentManager/products/tags/TagsPage';
import ViewTagsPage from './modules/ContentManager/products/tags/ViewTagsPage';
import AttributesPage from './modules/ContentManager/products/attributes/AttributesPage';
import ViewAttributesPage from './modules/ContentManager/products/attributes/ViewAttributesPage';
import AttributeTermsPage from './modules/ContentManager/products/attributes/terms/AttributeTermsPage';
import ViewAttributeTermsPage from './modules/ContentManager/products/attributes/terms/ViewAttributeTermsPage';
import CropsPage from './modules/ContentManager/crops/CropsPage';
import EditCropsPage from './modules/ContentManager/crops/EditCropsPage';
import CropTypesPage from './modules/ContentManager/crops/cropTypes/CropTypesPage';
import EditCropTypesPage from './modules/ContentManager/crops/cropTypes/EditCropTypesPage';
import SectorsPage from './modules/ContentManager/sectors/SectorsPage';
import EditSectorsPage from './modules/ContentManager/sectors/EditSectorsPage';
import FormulasPage from './modules/ContentManager/formulas/FormulasPage';
import EditFormulasPage from './modules/ContentManager/formulas/EditFormulasPage';
import PostsPage from './modules/ContentManager/posts/PostsPage';
import ViewPostsPage from './modules/ContentManager/posts/ViewPostsPage';
import PostCategoriesPage from './modules/ContentManager/posts/categories/PostCategoriesPage';
import ViewPostCategoriesPage from './modules/ContentManager/posts/categories/ViewPostCategoriesPage';
import PostTagsPage from './modules/ContentManager/posts/tags/PostTagsPage';
import ViewPostTagsPage from './modules/ContentManager/posts/tags/ViewPostTagsPage';
//import ErrorsPage from './modules/Errors/ErrorsPage'

export default function BasePage() {
	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */}
			<Redirect exact from='/' to='/dashboard' />
			<ContentRoute path='/dashboard' component={DashboardPage} />

			{/* Administrators */}
			<ContentRoute from='/admins' component={AdminsPage} />
			<ContentRoute from='/edit-admin/:id?' component={EditAdminsPage} />

			{/* Texts */}
			<ContentRoute from='/texts' component={TextsPage} />
			<ContentRoute from='/edit-text/:id' component={EditTextsPage} />

			{/* Languages */}
			{/* <ContentRoute from="/languages" component={LanguagesPage} />
			<ContentRoute from="/edit-language/:id?" component={EditLanguagesPage} /> */}

			{/* Users */}
			<ContentRoute from='/users' component={UsersPage} />
			<ContentRoute from='/view-user/:id' component={ViewUsersPage} />

			{/* Contacts */}
			<ContentRoute from='/contacts' component={ContactsPage} />
			<ContentRoute from='/view-contact/:id' component={ViewContactsPage} />

			{/* Products - Attributes - Terms of attributes */}
			<ContentRoute
				from='/product-terms-attributes'
				component={AttributeTermsPage}
			/>
			<ContentRoute
				from='/product-view-term-attribute/:id'
				component={ViewAttributeTermsPage}
			/>
			{/* Products - Attributes */}
			<ContentRoute from='/product-attributes' component={AttributesPage} />
			<ContentRoute
				from='/product-view-attribute/:id'
				component={ViewAttributesPage}
			/>
			{/* Products - Tags */}
			<ContentRoute from='/product-tags' component={TagsPage} />
			<ContentRoute from='/product-view-tag/:id' component={ViewTagsPage} />
			{/* Products - Categories */}
			<ContentRoute from='/product-categories' component={CategoriesPage} />
			<ContentRoute
				from='/product-view-category/:id'
				component={ViewCategoriesPage}
			/>
			{/* Products */}
			<ContentRoute from='/products' component={ProductsPage} />
			<ContentRoute from='/view-product/:id' component={ViewProductsPage} />

			{/* Crops - Types */}
			<ContentRoute from='/types-crops' component={CropTypesPage} />
			<ContentRoute from='/edit-type-crop/:id?' component={EditCropTypesPage} />
			{/* Crops */}
			<ContentRoute from='/crops' component={CropsPage} />
			<ContentRoute from='/edit-crop/:id?' component={EditCropsPage} />

			{/* Blog posts - Tags */}
			<ContentRoute from='/post-tags' component={PostTagsPage} />
			<ContentRoute from='/post-view-tag/:id' component={ViewPostTagsPage} />
			{/* Blog posts - Categories */}
			<ContentRoute from='/post-categories' component={PostCategoriesPage} />
			<ContentRoute
				from='/post-view-category/:id'
				component={ViewPostCategoriesPage}
			/>
			{/* Blog posts */}
			<ContentRoute from='/posts' component={PostsPage} />
			<ContentRoute from='/view-post/:id?' component={ViewPostsPage} />

			{/* Sectors */}
			<ContentRoute from='/sectors' component={SectorsPage} />
			<ContentRoute from='/edit-sector/:id?' component={EditSectorsPage} />

			{/* Formulas */}
			<ContentRoute from='/formulas' component={FormulasPage} />
			<ContentRoute from='/edit-formula/:id?' component={EditFormulasPage} />

			<Redirect to='/error' />
		</Switch>
	);
}
